import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type AppDownloadCtaSectionSliceResponse,
  requiredPrimaryFields,
} from './AppDownloadCtaSectionSliceTypes';
import {
  AppDownloadCtaSection,
  type AppDownloadCtaSectionProps,
} from '@yoweb/ui/components/sections/AppDownloadCtaSection';
import { coloredCtaSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';

type AppDownloadCtaSectionSliceProps = {
  slice: AppDownloadCtaSectionSliceResponse;
} & DataTestId &
  Omit<
    AppDownloadCtaSectionProps,
    | 'ctaAppStoreHref'
    | 'ctaAppStoreLabel'
    | 'ctaPlayStoreHref'
    | 'ctaPlayStoreLabel'
    | 'isRound'
    | 'title'
    | 'subtitle'
    | 'additionalDescription'
    | 'qrImage'
  >;

const AppDownloadCtaSectionSlice = ({
  slice,
  children,
  ...rest
}: AppDownloadCtaSectionSliceProps) => {
  const {
    ctaAppStoreHref,
    ctaAppStoreLabel,
    ctaPlayStoreHref,
    ctaPlayStoreLabel,
    isRound,
    title,
    subtitle,
    additionalDescription,
    qrImage,
  } = slice.primary;

  const props = {
    ctaAppStoreHref,
    isRound,
    ctaAppStoreLabel,
    ctaPlayStoreHref,
    ctaPlayStoreLabel,
    qrImage,
    title: title ?? '',
    subtitle: <PrismicRichText field={subtitle} components={coloredCtaSerializer} />,
    additionalDescription: (
      <PrismicRichText field={additionalDescription} components={coloredCtaSerializer} />
    ),
    ...rest,
  };

  return (
    <AppDownloadCtaSection {...props} {...rest}>
      {children}
    </AppDownloadCtaSection>
  );
};

export default withPrismicGuardian(AppDownloadCtaSectionSlice, {
  primaryRequired: requiredPrimaryFields,
});
