import Link from 'next/link';
import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import Image from 'next/legacy/image';
import type { StaticImageData } from 'next/image';

import AppleStoreEn from '@yoweb/ui/public/images/app-store/en/apple-store.svg';
import AppleStoreJp from '@yoweb/ui/public/images/app-store/ja/apple-store.svg';
import PlayStoreEn from '@yoweb/ui/public/images/app-store/en/google-play.svg';
import PlayStoreJp from '@yoweb/ui/public/images/app-store/ja/google-play.svg';
import AppDownloadHero from '@yoweb/ui/public/images/app-download-hero.svg';
import { Box, type BoxProps } from '@yoweb/ui/components/Box';
import { Title } from '@yoweb/ui/components/typography';
import { pxToRem, media } from '@yoweb/ui/styles/utils';
import { getRadii, getSpace } from '@yoweb/ui/styles/utils/theme';
import AnimateIn, { InView, DURATIONS } from '@yoweb/ui/components/AnimateIn';

export type AppDownloadCtaSectionProps = {
  className?: string;
  ctaAppStoreHref?: string | null;
  ctaAppStoreLabel?: string | null;
  ctaPlayStoreHref?: string | null;
  ctaPlayStoreLabel?: string | null;
  qrImage?: {
    url: string;
    dimensions: { width: number; height: number };
    alt: string;
    copyright: string;
  } | null;
  additionalDescription?: string | ReactNode;
  ctaOnClick?: () => void;
  subtitle?: string | ReactNode;
  title: string;
  isRound?: boolean;
  children?: ReactNode;
  navId?: string;
} & DataTestId &
  Pick<BoxProps, 'mb' | 'pb' | 'pt' | 'backgroundColor'>;

export const AppDownloadCtaSection = ({
  className = '',
  ctaAppStoreHref,
  ctaPlayStoreHref,
  isRound,
  additionalDescription,
  qrImage,
  ctaOnClick,
  subtitle,
  title,
  backgroundColor,
  navId,
  'data-testid': dataTestId,
}: AppDownloadCtaSectionProps) => {
  const config = {
    opacity: { animate: true, duration: DURATIONS.d67 },
    y: { animate: true, offset: 50, duration: DURATIONS.d33 },
  };

  const AppleStore = (
    ctaAppStoreHref?.includes('jp') ? AppleStoreJp : AppleStoreEn
  ) as StaticImageData;
  const PlayStore = (
    ctaPlayStoreHref?.includes('ja') ? PlayStoreJp : PlayStoreEn
  ) as StaticImageData;

  const ctaButtons = [
    {
      href: ctaAppStoreHref,
      storeName: 'App Store',
      ctaBtn: (
        <CTABtnImage src={AppleStore} alt={'Apple App Store Button'} height={50} width={150} />
      ),
    },
    {
      href: ctaPlayStoreHref,
      storeName: 'Google Play',
      ctaBtn: (
        <CTABtnImage src={PlayStore} alt={'Google Play Store Button'} height={50} width={160} />
      ),
    },
  ];

  return (
    <StyledAppDownloadSection
      id={navId}
      backgroundColor={backgroundColor}
      className={className}
      isRound={isRound}
      data-testid={dataTestId}
    >
      <StyledAppDownloadSectionContainer>
        <StyledAppDownloadContent>
          <StyledAppDownloadHeader>
            <InView>
              {(isInView) => (
                <AnimateIn isVisible={isInView} config={config}>
                  <Title as="h1" size={{ _: 'md', lg: 'lg' }} whiteSpace="pre-wrap">
                    {title}
                  </Title>
                </AnimateIn>
              )}
            </InView>

            {subtitle && (
              <InView>
                {(isInView) => (
                  <AnimateIn isVisible={isInView} config={config}>
                    <StyledSubtitleWrapper>{subtitle}</StyledSubtitleWrapper>
                  </AnimateIn>
                )}
              </InView>
            )}
          </StyledAppDownloadHeader>
          <StyledCTAContainer>
            {qrImage && (
              <StyledQRImage
                data-testid={`${dataTestId}-image`}
                src={qrImage.url}
                alt={qrImage.alt}
                width={96}
                height={96}
                priority
              />
            )}
            <StyledCTAButtonsContainer>
              {ctaButtons.map(
                ({ href, storeName, ctaBtn }) =>
                  (href || ctaOnClick) && (
                    <InView key={`${storeName}-cta-btn`}>
                      {(isInView) => (
                        <AnimateIn isVisible={isInView} config={config}>
                          <Box>
                            {href && (
                              <Link href={href} passHref legacyBehavior>
                                {ctaBtn}
                              </Link>
                            )}
                          </Box>
                        </AnimateIn>
                      )}
                    </InView>
                  ),
              )}
            </StyledCTAButtonsContainer>
          </StyledCTAContainer>

          {additionalDescription && (
            <InView>
              {(isInView) => (
                <AnimateIn isVisible={isInView} config={config}>
                  <StyledAdditionalDescriptionWrapper>
                    {additionalDescription}
                  </StyledAdditionalDescriptionWrapper>
                </AnimateIn>
              )}
            </InView>
          )}
        </StyledAppDownloadContent>
        <StyledAppDownloadPics>
          <Image src={AppDownloadHero as StaticImageData} alt="App Download Hero" />
        </StyledAppDownloadPics>
      </StyledAppDownloadSectionContainer>
    </StyledAppDownloadSection>
  );
};

type AppDownloadSectionProps = {
  isRound?: boolean;
} & BoxProps;

const StyledAppDownloadSection = styled(Box)<AppDownloadSectionProps>`
  && {
    display: flex;
    justify-content: center;
    font-size: ${pxToRem(32)};
    line-height: ${pxToRem(43)};
    height: 100%;
    ${media.sm`
      margin: 0 ${getSpace('normal2')};
    `}
    background: linear-gradient(339deg, #f8d472 0%, #fdebb9 101.26%);
    ${({ isRound }) =>
      isRound &&
      css`
        ${media.lg`
          border-radius: ${getRadii('large')};
        `}
      `}
  }
`;

const StyledAppDownloadSectionContainer = styled(Box)`
  max-width: ${pxToRem(1280)};
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${pxToRem(32)};
  line-height: ${pxToRem(43)};
  height: 100%;
  padding: 0;
  gap: 0;
  ${media.sm`;
      flex-direction: row;
      padding-left: ${pxToRem(118.5)};
    `};
`;
const StyledAppDownloadContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: ${getSpace('normal3')};
  padding: ${getSpace('medium2')} ${getSpace('normal2')} 0 ${getSpace('normal2')};
  ${media.sm`
      width: 50%;
      gap: ${getSpace('normal3')};
      font-size: ${pxToRem(40)};
      line-height: ${pxToRem(48)};
      text-align: start;
      padding: ${getSpace('medium3')} ${getSpace('medium3')} ${getSpace('medium3')} 0;
    `}
`;
const StyledAppDownloadHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${getSpace('normal2')};
  ${media.sm`
      gap: ${getSpace('normal3')};
    `}
`;
const StyledSubtitleWrapper = styled(Box)`
  p {
    font-size: ${pxToRem(18)};
    line-height: ${pxToRem(30)};
    font-weight: 400;
    text-align: center;
    ${media.sm`
      font-size: ${pxToRem(24)};
      text-align: start;
    `}
  }
`;

const StyledQRImage = styled(Image)`
  object-fit: cover;
  border-radius: ${getRadii('small')};
`;

const StyledCTAContainer = styled.div`
  display: flex;
  place-items: center;
  gap: ${getSpace('normal1')};
  flex-direction: column;
  ${media.sm`
    gap: ${getSpace('normal1')};
    flex-direction:row;
  `}
`;

const StyledCTAButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${getSpace('small3')};
  ${media.sm`
    gap: ${getSpace('normal1')};
  `}
`;

const CTABtnImage = styled(Image)`
  cursor: pointer;
`;

const StyledAdditionalDescriptionWrapper = styled(Box)`
  p {
    font-size: ${pxToRem(16)};
    line-height: ${pxToRem(26)};
    text-align: start;

    & > br {
      display: block;
      content: '';
      margin-top: ${pxToRem(8)};
    }

    ${media.sm`
      font-size: ${pxToRem(16)};
      text-align: start;
    `}
  }
`;

const StyledAppDownloadPics = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-left: ${pxToRem(58.91)};
  padding-right: ${pxToRem(58.91)};
  margin-top: ${pxToRem(57.06)};
  ${media.sm`
    width: 50%;
  `}
`;
