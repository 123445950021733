import type { RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const ColoredCtaSectionSliceType = 'colored_cta_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title'] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  ctaHref?: string | null;
  ctaLabel?: string | null;
  rockVariant?: RockVariants | null;
  subtitle?: RichTextField | null;
  subtitleMaxWidth?: number;
  title: string;
  titleMaxWidth?: number;
  disclaimer?: RichTextField;
  navId?: string;
  navLabel?: string;
};

export type ColoredCtaSectionSliceResponse = PrismicSlice<
  typeof ColoredCtaSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
