import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type ColoredCtaSectionSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './ColoredCtaSectionSliceTypes';
import { ColoredCtaSection } from '@yoweb/ui/components/sections/ColoredCtaSection';
import type { ColoredCtaSectionProps } from '@yoweb/ui/components/sections/ColoredCtaSection';
import {
  coloredCtaSerializer,
  coloredCtaDisclaimerSerializer,
} from '@yoweb/prismic-slicemachine/utils/serializers';

type ColoredCtaSectionSliceProps = {
  slice: ColoredCtaSectionSliceResponse;
} & DataTestId &
  Omit<ColoredCtaSectionProps, 'ctaHref' | 'ctaLabel' | 'title' | 'subtitle' | 'disclaimer'>;

const ColoredCtaSectionSlice = ({ slice, children, ...rest }: ColoredCtaSectionSliceProps) => {
  const {
    ctaHref,
    ctaLabel,
    title,
    subtitle,
    rockVariant,
    titleMaxWidth,
    subtitleMaxWidth,
    disclaimer,
    navId,
  } = slice.primary;

  const props = {
    ctaHref,
    ctaLabel,
    title: title ?? '',
    subtitle: <PrismicRichText field={subtitle} components={coloredCtaSerializer} />,
    rockVariant,
    titleMaxWidth,
    subtitleMaxWidth,
    disclaimer: <PrismicRichText field={disclaimer} components={coloredCtaDisclaimerSerializer} />,
    navId,
    ...rest,
  };

  return (
    <ColoredCtaSection {...props} disclaimer={props.disclaimer} {...rest}>
      {children}
    </ColoredCtaSection>
  );
};

export default withPrismicGuardian(ColoredCtaSectionSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
