import LottieReact from 'lottie-react';
import styled from 'styled-components';

import IconBase, { type IconProps } from '@yoweb/ui/components/Icon/IconBase';
import rocksAnimationTwoSkewed from './lottie/rocks-two-skewed-right.json';
import rocksAnimationThree from './lottie/rocks-three.json';
import rocksAnimationStraightLine from './lottie/three-straight-line.json';

type RockProps = IconProps & { rockVariant?: RockVariants; autoPlay?: boolean };

export const Rocks = ({ rockVariant = 'three', autoPlay, ...props }: RockProps) => {
  switch (rockVariant) {
    case 'lottie-three':
      return <LottieRocks loop={false} autoPlay={autoPlay} animationData={rocksAnimationThree} />;
    case 'lottie-two-skewed-right':
      return (
        <LottieRocks loop={false} autoPlay={autoPlay} animationData={rocksAnimationTwoSkewed} />
      );
    case 'three-straight-line':
      return (
        <LottieRocks loop={false} autoPlay={autoPlay} animationData={rocksAnimationStraightLine} />
      );
    case 'three-skewed-right':
      return (
        <IconBase viewBox="0 0 31 48" {...props}>
          <path
            d="M28.095 39.762c7.4-9.749-3.742-18.63-7.802-20.159-4.06-1.528-10.666-.022-14.76 2.426C.416 25.088-.09 35.348.974 38.949c1.064 3.6 11.637 7.966 15.065 8.658 2.743.554 4.655 1.904 12.055-7.845zM9.072 19.41c3.144-1.217 6.092-5.08 6.092-7.035 0-1.954-1.46-8.156-8.406-6.093-1.777.528-5.122 2.694-5.698 3.714-.922 1.632-.763 4.61 0 5.368.751.747 4.868 5.261 8.012 4.045zM12.993 5.345c-.94-1.164-.172-2.999.265-3.752.438-.754 3.204-2.857 5.42-.525.567.597 1.627 2.242 1.366 2.693-.422.726-2.186 3.173-2.64 3.021-.364-.121-3.471-.272-4.411-1.437z"
            fill="currentcolor"
          />
        </IconBase>
      );
    case 'two-skewed-right':
      return (
        <IconBase viewBox="0 0 31 48" {...props}>
          <path
            d="M19.761 14.707c-8.632 0-16.74 4.755-18.717 10.065-1.976 5.31-1.04 11.474 1.907 16.312 1.382 2.269 3.237 4.322 5.609 5.52 2.09 1.054 4.464 1.39 6.796 1.601 3.513.318 7.048.387 10.573.265 2.276-.079 4.64-.265 6.61-1.408 1.503-.873 11.431-7.546 10.15-18.65-1.28-11.103-14.296-13.705-22.928-13.705z"
            fill="currentcolor"
          />
          <path
            d="M23.3 8.213a9.085 9.085 0 003.573 5.892c1.865 1.365 4.195 1.96 6.498 2.16 1.585.137 3.298.066 4.59-.86 1.193-.853 1.821-2.285 2.226-3.693.53-1.852.765-3.788.692-5.712-.036-.934-.153-1.899-.63-2.702-.96-1.616-3.028-2.113-4.876-2.447C30.147-.095 22.148.657 23.3 8.213z"
            fill="currentcolor"
          />
        </IconBase>
      );
    default:
      return (
        <IconBase viewBox="0 0 30 52" {...props}>
          <path
            d="M26.96 44.186c7.052-9.248-3.566-17.673-7.434-19.123-3.869-1.45-10.165-.021-14.067 2.3C.583 30.267.102 39.999 1.115 43.414c1.014 3.416 11.09 7.558 14.357 8.214 2.614.526 4.436 1.806 11.488-7.441zM16.415 21.645c2.996-1.153 5.805-4.819 5.805-6.673s-1.392-7.736-8.01-5.78c-1.694.502-4.881 2.556-5.43 3.523-.88 1.549-.727 4.373 0 5.093.715.709 4.639 4.99 7.635 3.837zM12.568 5.07c-.895-1.104-.164-2.844.253-3.56.417-.714 3.053-2.71 5.165-.497.54.567 1.55 2.127 1.301 2.555-.402.689-2.082 3.01-2.516 2.866-.346-.116-3.307-.259-4.203-1.364z"
            fill="currentcolor"
          />
        </IconBase>
      );
  }
};

const LottieRocks = styled(LottieReact)`
  max-width: 48px;
`;

export default Rocks;
