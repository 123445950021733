import type { RichTextField } from '@prismicio/types';

import type {
  ArrayToUnion,
  CreateRequiredFields,
  PrismicSlice,
} from '@yoweb/prismic-slicemachine/slices/slicesTypes';

export const AppDownloadCtaSectionSliceType = 'app_download_cta_section_slice';

// Required fields in slice
export const requiredPrimaryFields = ['title'] as const; // Array of all required primary fields.
export const requiredItemsFields = [] as const; // Array of all required items fields.

type Primary = {
  title: string;
  subtitle?: RichTextField | null;
  ctaAppStoreHref?: string | null;
  ctaAppStoreLabel?: string | null;
  ctaPlayStoreHref?: string | null;
  ctaPlayStoreLabel?: string | null;
  qrImage: {
    url: string;
    dimensions: { width: number; height: number };
    alt: string;
    copyright: string;
  };
  isRound?: boolean;
  additionalDescription?: RichTextField | null;
};

export type AppDownloadCtaSectionSliceResponse = PrismicSlice<
  typeof AppDownloadCtaSectionSliceType,
  CreateRequiredFields<ArrayToUnion<typeof requiredPrimaryFields>, Primary>
>;
