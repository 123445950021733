import Link from 'next/link';
import type { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@yoweb/ui/components/buttons/Button';
import { Box, type BoxProps } from '@yoweb/ui/components/Box';
import { Rocks } from '@yoweb/ui/components/Icon/Rocks';
import { Title } from '@yoweb/ui/components/typography';
import { pxToRem, media } from '@yoweb/ui/styles/utils';
import { getSpace, getRadii } from '@yoweb/ui/styles/utils/theme';
import AnimateIn, { InView, DURATIONS } from '@yoweb/ui/components/AnimateIn';

export type ColoredCtaSectionProps = {
  className?: string;
  ctaHref?: string | null;
  ctaOnClick?: () => void;
  ctaLabel?: string | null;
  rockVariant?: RockVariants | null;
  subtitle?: string | ReactNode;
  title: string;
  titleMaxWidth?: number;
  subtitleMaxWidth?: number;
  atBottom?: boolean;
  removeRockVariant?: boolean;
  roundWithPadding?: boolean;
  children?: ReactNode;
  disclaimer?: ReactNode;
  navId?: string;
} & DataTestId &
  Pick<BoxProps, 'mb' | 'pb' | 'pt' | 'backgroundColor'>;

export const ColoredCtaSection = ({
  className = '',
  ctaHref,
  ctaOnClick,
  ctaLabel,
  rockVariant,
  subtitle,
  title,
  titleMaxWidth,
  subtitleMaxWidth,
  atBottom,
  removeRockVariant = false,
  roundWithPadding = true,
  children,
  pt = 'large3',
  pb = 'large3',
  mb,
  disclaimer,
  backgroundColor,
  navId,
  'data-testid': dataTestId,
}: ColoredCtaSectionProps) => {
  const config = {
    opacity: { animate: true, duration: DURATIONS.d67 },
    y: { animate: true, offset: 50, duration: DURATIONS.d33 },
  };
  const withDisclaimer = !!disclaimer;

  return (
    <ColoredSection
      id={navId}
      backgroundColor={backgroundColor}
      className={className}
      pb={pb}
      pt={pt}
      mb={mb}
      atBottom={atBottom}
      roundWithPadding={roundWithPadding}
      data-testid={dataTestId}
    >
      <ColoredContent data-testid="colored-cta-content">
        <InView>
          {(isInView) =>
            !removeRockVariant &&
            rockVariant &&
            isInView && (
              <Box mb={{ _: 'normal2' }}>
                <Rocks height={48} width={30} rockVariant={rockVariant} autoPlay={isInView} />
              </Box>
            )
          }
        </InView>

        <InView>
          {(isInView) => (
            <AnimateIn isVisible={isInView} config={config}>
              <Title size="lg" textAlign="center" maxWidth={titleMaxWidth} whiteSpace="pre-wrap">
                {title}
              </Title>
            </AnimateIn>
          )}
        </InView>

        {subtitle && (
          <InView>
            {(isInView) => (
              <AnimateIn isVisible={isInView} config={config}>
                <SubtitleWrapper my="normal2" maxWidth={subtitleMaxWidth}>
                  {subtitle}
                </SubtitleWrapper>
              </AnimateIn>
            )}
          </InView>
        )}

        {(ctaHref || ctaOnClick) && (
          <InView>
            {(isInView) => (
              <AnimateIn isVisible={isInView} config={config}>
                <Box mt={{ _: 'normal3', md: 'normal2' }} mb={withDisclaimer ? 'none' : 'normal3'}>
                  {ctaHref && (
                    <Link href={ctaHref} passHref legacyBehavior>
                      <Button data-testid="coloredCta-button" onClick={ctaOnClick} as="a" size="lg">
                        {ctaLabel}
                      </Button>
                    </Link>
                  )}
                  {ctaOnClick && !ctaHref && (
                    <Button as="a" onClick={ctaOnClick} size="lg" data-testid="coloredCta-button">
                      {ctaLabel}
                    </Button>
                  )}
                </Box>
              </AnimateIn>
            )}
          </InView>
        )}
        {withDisclaimer && <Box>{disclaimer}</Box>}
        <InView>
          {(isInView) => (
            <AnimateIn isVisible={isInView} config={config}>
              {children}
            </AnimateIn>
          )}
        </InView>
      </ColoredContent>
    </ColoredSection>
  );
};

type ColoredSectionProps = {
  atBottom?: boolean;
  roundWithPadding?: boolean;
} & BoxProps;

const ColoredSection = styled(Box)<ColoredSectionProps>`
  && {
    align-items: center;
    display: flex;
    justify-content: center;
    max-height: ${pxToRem(680)};
    padding-left: ${getSpace('normal2')};
    padding-right: ${getSpace('normal2')};

    ${({ atBottom }) =>
      atBottom &&
      css`
        margin-bottom: ${pxToRem(-24)};

        ${media.md`
          margin-bottom: ${getSpace('normal3')};
        `}
      `}

    ${({ roundWithPadding }) =>
      roundWithPadding &&
      css`
        ${media.lg`
          border-radius: ${getRadii('medium')};
          margin: ${getSpace('normal3')};
        `}
      `}
  }
`;

const ColoredContent = styled.div`
  display: grid;
  justify-items: center;
  margin: 0 auto;
  row-gap: ${getSpace('small1')};

  ${media.md`
    max-width: ${pxToRem(563)};
  `}

  ${media.lg`
    max-width: ${pxToRem(808)};
  `}
`;

const SubtitleWrapper = styled(Box)`
  p {
    font-size: ${pxToRem(24)};
    line-height: ${pxToRem(32)};
    font-weight: 400;
  }
`;
